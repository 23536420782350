import React from "react";
import { GetServerSideProps } from "next";
import { getCurrentPartner } from "configs/index";
import { abbreviations } from "constants/partners";
import { checkForLogin, checkForMobileBrowser } from "helpers/redirects";
import { defaultRedirect, urls } from "constants/urls";
import { useTranslation } from "hooks/i18n";
import { getLanguageUrlPrefixByNextContext } from "utils/nextjs/languages";
import AuthPageContainer from "containers/PageContainers/AuthPageContainer";
import AuthLogin from "containers/Pages/Auth/Login";

export default function LoginPage() {
  const partner = getCurrentPartner();
  const { t } = useTranslation("common");
  const titlePath = t("page_titles.login");
  return (
    <AuthPageContainer browserPageTitle={`${abbreviations[partner]} ${t("merchant")} | ${titlePath}`}>
      <AuthLogin />
    </AuthPageContainer>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const language = getLanguageUrlPrefixByNextContext(context);
  const isLoggedIn = checkForLogin(context);
  const isMobile = checkForMobileBrowser(context);

  if (isMobile) {
    return { redirect: { destination: `${language}${urls.download.url}`, permanent: false } };
  }

  if (isLoggedIn) {
    return { redirect: { destination: `${language}${defaultRedirect.url}`, permanent: false } };
  }

  return { props: {} };
};
