import React from "react";
import styled from "styled-components";
import Icon from "primitives/Icon";

const StyledToggleShowPasswordButton = styled.button.attrs({ type: "button" })`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &:hover .icon path {
    stroke: ${(props) => props.theme.colors.black};
  }
`;

interface ToggleShowPasswordButtonInterface {
  showed: boolean;
  toggle: () => void;
}

function ToggleShowPasswordButton({ showed, toggle }: ToggleShowPasswordButtonInterface) {
  return (
    <StyledToggleShowPasswordButton tabIndex={0} onClick={toggle}>
      <Icon icon={showed ? "hide-password" : "show-password"} />
    </StyledToggleShowPasswordButton>
  );
}

export default React.memo(ToggleShowPasswordButton);
