import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useToggle } from "@worksolutions/react-utils";
import TypographyLink from "primitives/Typography/TypographyLink";
import TextField from "primitives/TextField";
import { InputFieldType } from "primitives/InputField";
import Typography from "primitives/Typography";
import { useTranslation } from "hooks/i18n";
import { useLogEvent } from "hooks/analytics";
import { useCustomFormik } from "hooks/useCustomFormik";
import { initialLoginValues, useLogin } from "store/auth/hooks";
import { getCurrentPartner } from "configs";
import { urls } from "constants/urls";
import { KRYM_EDA } from "constants/partners";
import { getValidationText } from "helpers/getValidationText";
import { PASS_MIN_LENGTH } from "helpers/validators";
import { useAuthHandleSubmit } from "containers/Pages/Auth/common/hooks/useAuthHandleSubmit";
import AuthHeader from "containers/Pages/Auth/common/AuthHeader";
import AuthForm from "containers/Pages/Auth/common/AuthForm";
import AuthButton from "containers/Pages/Auth/common/AuthButton";

import { validationSchema } from "./schema";
import ToggleShowPasswordButton from "./ToggleShowPasswordButton";

const HeaderWrapper = styled.div`
  margin-bottom: 34px;
`;

const EmailInputWrapper = styled.div`
  margin-bottom: 35px;
`;

const PasswordInputWrapper = styled.div`
  margin-bottom: 56px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 18px;
`;

const ForgetPasswordWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -29px;
`;

const SubText = styled.div`
  margin-bottom: 8px;
  text-align: center;
`;

const RegisterText = styled.div`
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
`;

function SendForm() {
  const [showPass, toggleShowPass] = useToggle(false);
  const { login, isErrorSubmit, isSubmitting, merchantData } = useLogin();
  const { t } = useTranslation("auth");
  const [logEvent, logEventFabric] = useLogEvent();

  const passInputRef = useRef<HTMLInputElement>(null);
  const partner = getCurrentPartner() === KRYM_EDA ? t("common:partner.krymEda_plural") : "Broniboy";

  const [formik, getFormik] = useCustomFormik({
    initialValues: initialLoginValues,
    validationSchema,
    onSubmit: login,
  });

  useEffect(() => logEvent("LogIn_Appear"), [logEvent]);

  useEffect(() => {
    if (!isErrorSubmit) return;
    getFormik().setFieldValue("password", "");
    passInputRef.current!.focus();
  }, [getFormik, isErrorSubmit]);

  const handleFormSubmit = useAuthHandleSubmit(formik, {
    clickEventName: "LogIn_Clicked_LogIn",
    invalidFormEventName: "LogIn_Failed_To_Login",
  });

  return (
    <AuthForm onSubmit={handleFormSubmit} noValidate>
      <HeaderWrapper>
        <AuthHeader text={t("login.title")} />
      </HeaderWrapper>
      <EmailInputWrapper>
        <TextField
          fieldType={InputFieldType.COMPACT}
          label={t("login.email")}
          type="email"
          name="email"
          value={formik.values.email}
          autofocus
          errorMessage={formik.touched.email ? getValidationText(t, { text: formik.errors.email }) : undefined}
          onChange={formik.handleChange}
        />
      </EmailInputWrapper>
      <PasswordInputWrapper>
        <TextField
          inputRef={passInputRef}
          fieldType={InputFieldType.COMPACT}
          label={t("login.password")}
          type={showPass ? "text" : "password"}
          name="password"
          value={formik.values.password}
          errorMessage={
            formik.touched.password
              ? getValidationText(t, { text: formik.errors.password, templates: { count: PASS_MIN_LENGTH } })
              : undefined
          }
          fieldItemRight={<ToggleShowPasswordButton showed={showPass} toggle={toggleShowPass} />}
          onChange={formik.handleChange}
        />
        <ForgetPasswordWrapper>
          <TypographyLink
            type="info-medium"
            linkTheme="black"
            href={urls.restore.url}
            onClick={logEventFabric("LogIn_Clicked_ResetPassword")}
          >
            {t("login.forget_password")}
          </TypographyLink>
        </ForgetPasswordWrapper>
      </PasswordInputWrapper>
      <ButtonWrapper>
        <AuthButton type="submit" disabled={isSubmitting || !formik.dirty}>
          {t("login.submit")}
        </AuthButton>
      </ButtonWrapper>
      <SubText>
        <Typography type="info-medium" color="secondary">
          {t("login.want_to_connect")} {partner}?
        </Typography>
      </SubText>
      <RegisterText>
        <TypographyLink
          rel="noreferrer"
          href={urls.subscription.url}
          target="_blank"
          type="info-medium"
          showExternalIcon={false}
          linkTheme="external"
          onClick={logEventFabric("LogIn_Clicked_Add_Merchant")}
        >
          {t("login.connect")}
        </TypographyLink>
      </RegisterText>
    </AuthForm>
  );
}

export default React.memo(SendForm);
